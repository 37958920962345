.headerWrapper{
    background-color: #409eff;
    color: #eee;
    z-index: 9999;
    height: 50px;
    line-height: 50px;
    width: 100vw;
    position: absolute;
    text-align: center;
}
.headerWrapper a{
    color: red;
}

.ant-drawer{
    margin-top: 50px;
    height:calc(100% - 50px)
}
#navigation-drawer .library-nav, #navigation-drawer .library-cont{
    height: calc(100vh - 124px - 50px);
}