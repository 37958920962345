#search{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: var(--search-top);
    z-index: 1;
}

#search-logo{
    display: inline-block;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 25px;
    cursor: pointer;
}
#search-logo img{
    height: 80px;
}

#search-box {
    width: var(--search-width);
    height: var(--search-height);
    border-radius: var(--search-radius);
    background: var(--search-bg-color);
    border: var(--search-border-color) var(--search-border-width) solid;
    box-sizing: content-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    transition: opacity 0.5s;
    color: var(--search-color);
    position: relative;
}
@media screen and (max-width: 800px) {
    #search-box{
        width: 75vw;
    }
}
#search-box .search-icon{
    height: var(--search-height);
    width: var(--search-height);
    object-fit: contain;
    cursor: pointer;
    padding: 10px;
}
#search-box .anticon-caret-down{
    position: absolute;
    top: 50%;
    left: calc(var(--search-height) - 12px);
    color: #bbb;
    font-size: 12px;
    transform: translateY(-50%);
}
#search-box input {
    height: var(--search-height);
    background-color: transparent;
    flex: 1;
    padding: 0 0.5rem;
}
#search-box .toggle-btn{
    height: var(--search-height);
    width: var(--search-height);
    line-height: var(--search-height);
}
#search-box .search-btn{
    font-size: 2rem;
    height: var(--search-height);
    width: var(--search-height);
    line-height: var(--search-height);
    transition: background-color 0.3s;
    padding-top: 4px;
}
#search-box .search-btn:hover{
    background-color: #ddd;
}
#search-box .toggle-btn{
    margin-right: 0.25rem;
}

#search-list,
#auto-word{
    position: absolute;
    width: 100%;
    margin-top: 5px;
    border-radius: 5px;
    overflow: hidden;
    background: rgba(255,255,255,0.5);
    backdrop-filter: blur(5px);
}
#auto-word li{
    padding: 0.5rem 0.5rem;
    transition: all 0.3s;
    cursor: pointer;
}
#auto-word li:not(:first-child){
    border-top: 1px solid rgba(255,255,255,0.5);
}
#auto-word li:hover{
    background: rgba(255,255,255,0.8);
    padding: 0.5rem 1rem;
}


#search-list{
    display: flex;
    gap: 0.25rem;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.5);
    backdrop-filter: blur(5px);
    padding: 0.5rem;
}

#search-list li{
    background: rgba(255,255,255,0.5);
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.3s;
}
#search-list li.active,
#search-list li:hover{
    background: rgba(255,255,255,1);
}
#search-list li:hover {
    transform: scale(1.02);
}

#search-list li img{
    height: 2rem;
    width: 4rem;
    object-fit: contain;
}
#search-list li > span{
    display: inline-block;
    /*font-size: 1.5rem;*/
    height: 2rem;
    line-height: 2rem;
    float: left;
    max-width: 6rem;
    min-width: 4rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
}
