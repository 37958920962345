/* 导航分类 */
#navigation-drawer .ant-tabs-nav-list{
    padding: 0 2rem;
}

#navigation-drawer .navigation-library .ant-tabs-nav-list{
    padding: 0;
}
#navigation-drawer .navigation-library header{
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    border-top: 1px solid #ddd;
}
#navigation-drawer .navigation-library main{
    display: flex;
}
#navigation-drawer .library-nav,
#navigation-drawer .library-content{
    height: calc(100vh - 124px);
    overflow: auto;
}
#navigation-drawer  .library-nav{
    background-color: #ddd;
    overflow: auto;
    width: 5rem;
}
#navigation-drawer .library-nav li{
    padding: 0.5rem;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;
}
#navigation-drawer .library-nav li:not(:first-child){
    border-top: 1px solid #ccc;
}
#navigation-drawer .library-nav li:hover,
#navigation-drawer .library-nav li.active{
    background-color: #3a90ff;
    color: #fff;
}

/* 导航列表 */
#navigation-drawer .library-content{
    flex: 1;
    padding: 1rem;
}
#navigation-drawer .library-content ul{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
#navigation-drawer .library-content li{
    position: relative;
    height: 8rem;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.4);
    overflow: hidden;
    display: flex;
    gap: 0.25rem;
    padding: 0.5rem;
    cursor: pointer;
}
#navigation-drawer .library-content li img{
    height: 100%;
    border-radius: 5%;
}
#navigation-drawer .library-content li > a{
    opacity: 0;
    position: absolute;
    right: 0.5rem;
    top: 0.2rem;
    font-size: 1.5rem;
    transition: all 0.2s;
}
#navigation-drawer .library-content li:hover > a{
    opacity: 1;
}
#navigation-drawer .library-content li > div{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
#navigation-drawer .library-content li > div span:first-child{
    height: 2rem;
    line-height: 2rem;
}
#navigation-drawer .library-content li > div span:last-child{
    height: calc(100% - 3rem);
}
#navigation-drawer .library-content .not-more{
    margin-top: 0.5rem;
    text-align: center;
}

#navigation-drawer form{
   margin: 1.5rem;
}
#navigation-drawer form .preview{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 6rem;
}
#navigation-drawer form .preview .icon{
    width: 100%;
    height: 6rem;
    border: 1px solid #ddd;
    line-height: 6rem;
    text-align: center;
    overflow: hidden;
    font-size: 2em;
    border-radius: 5%;
    color: #fff;
}
#navigation-drawer form .preview img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    float: left;
}
#navigation-drawer form .preview >span{
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
}
#navigation-drawer form .icon-list{
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}
#navigation-drawer form .icon-list .icon{
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
}
#navigation-drawer form .icon-list .icon.active{
    border: 1px solid #f50;
}
#navigation-drawer form .icon-list img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
#navigation-drawer form .color-list{
    display: flex;
    gap: 0.6rem;
}
#navigation-drawer form .color-list li{
   width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: currentColor;
    cursor: pointer;
}
#navigation-drawer form .color-list li.active{
    box-shadow: 0 0 0 2px #fff,0 0 0 4px currentColor;
}
