#right-top{
    position: absolute;
    display: flex;
    right: 2rem;
    top:2rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

#wallpaper-btn,
#setting-btn{
    color: #fff;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    font-size: 2em;
    display: flex;
    justify-content: center;
    border-radius: 5%;
    align-items: center;
    transition: all 0.2s;
    padding: 0.5rem;
    z-index: 1000;
}
#setting-btn:hover,
#wallpaper-btn:hover{
    background: rgba(0,0,0,0.3);
}


#theme-btn{
    position: absolute;
    left: 5rem;
    bottom: 2rem;
    color: #fff;
    cursor: pointer;
    font-size: 2em;
    transition: transform 0.3s;
    z-index: 1000;
}
#theme-btn:hover{
    transform: scale(1.05);
}

#weather{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: #fff;
    font-size: 1.25rem;
    cursor:pointer;
    user-select: none;
    z-index: 1000;
}
#weather > img{
    height: 1em;
}

#weather-full{
    font-size: 1rem;
    position: absolute;
    background-color: #0005;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    top: 0;
    right: 0;
    z-index: 1002;
}
#weather-full > header{
    display: flex;
    gap: 1rem;
    padding: 0 1rem;
    margin: 1rem 0;
}
#weather-full > header div{
   display: flex;
    gap: 0.5rem;
}
#weather-full > hr{
    height: 1px;
    background-color: rgba(0,0,0,0.1);
    transform: scaleY(0.5);
}
#weather-full > ul{
    display: flex;
    padding: 1rem;
    gap: 0.5rem;
    text-align: center;
    margin: 0.5rem;
}
#weather-full > ul li{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 5rem;
}
#weather-full img{
    height: 2em;
}

#more-theme{
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-size: 12px;
    border: 1px solid currentColor;
    color: #fff;
    border-radius: 5px;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    user-select: none;
}

#windmill{
    position: absolute;
    top: 3rem;
    left: 5rem;
    user-select: none;
    z-index: 999;
}
#windmill:before{
    content: '';
    position: absolute;
    width: 3px;
    background-color: #fff;
    height: 100px;
    left: calc(50% - 2px);
    top: -75px;
    z-index: -1;
}
#windmill  img{
    cursor: pointer;
    width: 60px;
    height: 60px;
    object-fit: contain;
}
#windmill.rotating  img{
    animation: rotating 1s infinite linear;
}
